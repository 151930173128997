// import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './custome_swiper.scss';

// import required modules
import { Pagination } from 'swiper/modules';
// import banner from "../../Assets/image/banner.png"
// import { useSelector } from 'react-redux'

export default function CustomSwiper({ Content }: any) {

    return (
        <>
            <Swiper
                slidesPerView={'auto'}
                spaceBetween={30}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
            >
                {(Content ?? []).map((images: any, index: any) => {
                    return (<SwiperSlide key={index}>  <img src={images?.banner} alt="" /></SwiperSlide>)
                })}

            </Swiper>
        </>
    );
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch } from '../../state_store/index' // Importing AppDispatch type for Thunks
import { postRequest } from '../../services'
import { useDispatch } from 'react-redux';
import { setLoader } from '../../state_store/loadercontroller';


interface CategoryState {
    data: any[]
}

const initialState: CategoryState = {
    data: []
}

const CategorySlice = createSlice({
    name: 'Category',
    initialState,
    reducers: {
        setCategory(state, action: PayloadAction<any[] | ((prevState: any[]) => any[])>) {
            if (typeof action.payload === 'function') {
                state.data = action.payload(state.data);
            } else {
                state.data = action.payload;  // Update state with action payload
            }
        }
    }
})

// Export actions
export const { setCategory } = CategorySlice.actions
// Export reducer
export default CategorySlice.reducer



// const makeFilterString = (filterData: any) => {
//     if (!Array.isArray(filterData) || filterData.length === 0) {
//         return '';
//     }
//     return filterData.map((id) => encodeURIComponent(id)).join('%2C');
// };
// Thunk Function (Async Action Creator)

export const fetchProductsCategories = ({ filter, page, rpp, store_id }: { filter: any, page: any, rpp: any, store_id: any }) => async (dispatch: AppDispatch) => {
    try {
        // dispatch(setLoader({ open: true }))
        // Add filterCategory if it exists
        let URL = `products`;

        interface Filter {
            key: string;
            value: string | any;
        }

        let reqJson = {
            page: page,
            limit: 10,
            filter: [] as Filter[]
        };
        if (filter?.length > 0) {
            // console.log(reqJson);
            const newFilter = filter.filter((item: any) => item.value.length > 0);
            reqJson.filter.push(...newFilter);
        }

        reqJson.filter.push({ key: "store_id", value: store_id });
        console.log(reqJson);

        const response: any = await postRequest(URL, reqJson)
        if (response.status) {
            dispatch(setCategory(response.data?.data))
            // dispatch(setLoader({ open: false }))
        } else {
            dispatch(setCategory([]))
            // dispatch(setLoader({ open: false }))
        }
    } catch (error) {
        console.error('Error fetching Category data', error)
        // dispatch(setLoader({ open: false }))
        // Optionally, handle the error by dispatching an error action or setting a specific error state
    }
}

export const AddProductsCategories = ({ filter, page, rpp, store_id }: { filter: any, page: any, rpp: any, store_id: any }) => async (dispatch: AppDispatch) => {
    try {
        // Add filterCategory if it exists
        let URL = `products`;

        interface Filter {
            key: string;
            value: string | any;
        }

        let reqJson = {
            page: page,
            limit: 10,
            filter: [] as Filter[]
        };
        if (filter?.length > 0) {
            // console.log(reqJson);
            const newFilter = filter.filter((item: any) => item.value.length > 0);
            reqJson.filter.push(...newFilter);
        }

        reqJson.filter.push({ key: "store_id", value: store_id });
        
        const response: any = await postRequest(URL, reqJson)
        if (response.status) {
            dispatch(setCategory((prevState: any) => [...prevState, ...(response.data?.data || [])]));
        } else {
            dispatch(setCategory([]))
        }
    } catch (error) {
        console.error('Error fetching Category data', error)
        // Optionally, handle the error by dispatching an error action or setting a specific error state
    }
}
export const useAppDispatch: () => AppDispatch = useDispatch;
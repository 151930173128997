import Layout from "../../component/Layout/index"
import SwiperComponent from "../../component/Swiper";
import Description from "../../component/Text/Description";
import Title from "../../component/Text/Title";
import "./home.scss"
import NormalCart from "../../component/Cart/NormalCart";
import ProductCart from "../../component/Cart/ProductCart";
import CustomButton from "../../component/Button";
import CustomSwiper from "../../component/Swiper/custom_swiper";
import { useSelector } from 'react-redux'
import CustomerSupport from "../../component/Footer/CustomerSupport";




function Home() {
    const homePageData = useSelector((state: any) => state.homePage.data)
    return (
        <Layout>
            <div className="Home-Page">
                <SwiperComponent Content={homePageData[0]?.store_design?.carosel} />
                <div className="Home-Page-Content">
                    <div className="section">
                        <div className="section-information">
                            <Title>Browse The Range</Title>
                            <Description> Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Description>
                        </div>
                        <div className="section-cart">
                            <NormalCart data={homePageData[0]?.categories ?? []} />
                        </div>
                        <div className="section-information">
                            <Title>Our Products</Title>
                        </div>
                        <div className="section-cart">
                            <ProductCart data={homePageData[0]?.products ?? []} />
                        </div>

                        <CustomButton lable={"Show more"} type="outlined" />

                    </div>
                </div>
                <div className="Home-Page-Options-Component">
                    <div className="options-cart slider-component">
                        <div className="section-component info-section">
                            <Title>{homePageData[0]?.store_design.gallery.title}</Title>
                            <Description>{homePageData[0]?.store_design.gallery.description}</Description>
                            <CustomButton lable={"Explore more"} type="contained" />
                        </div>
                        <div className="section-component">
                            <CustomSwiper Content={homePageData[0]?.store_design.gallery.images} />
                        </div>
                    </div>
                </div>
                <div className="Home-Page-Customer-Support">
                    <CustomerSupport image={homePageData[0]?.store_design?.footer_image?.banner} />
                </div>
            </div>
        </Layout>
    );
}

export default Home;

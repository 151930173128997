import React, { useEffect, useRef } from 'react';
import ResponsiveAppBar from "../Header"
import "./pagelayout.scss"
import Footer from '../Footer/Footer';
import SelfManagedSnackbar from "../Snackbar/index";
import debounce from 'lodash/debounce';
import { useSelector } from 'react-redux'
import LoaderImage from "../../assets/loader.json";
import Lottie from "lottie-react";


interface LayoutProps {
  children: React.ReactNode;
  name?: string; // Optional name prop
  handleScroll?: () => void; // Optional function prop
}
const Layout: React.FC<LayoutProps> = ({ children, name, handleScroll }) => {

  const layoutRef = useRef<HTMLDivElement>(null);
  const LoaderState = useSelector((state: any) => state.loader.data)
  useEffect(() => {
    const handleDivScroll = debounce(() => {
      if (!layoutRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = layoutRef.current;
      console.log(scrollTop, scrollHeight, clientHeight);
      if (scrollTop + clientHeight >= scrollHeight - (scrollHeight / 2)) {
        if (handleScroll) {
          handleScroll();
        }
      }
    }, 300); // 300ms delay

    const layoutElement = layoutRef.current;
    if (layoutElement) {
      layoutElement.addEventListener("scroll", handleDivScroll);
    }

    return () => {
      layoutElement?.removeEventListener("scroll", handleDivScroll);
    };
  }, [handleScroll]);

  return (
    <div className="Layout-container" ref={layoutRef}>
      <div className='Header-container'>
        <ResponsiveAppBar />
      </div>
      <div className='Body-container'>
        {LoaderState?.open ? <Lottie className="sm:h-[700px] h-[200px] w-full" animationData={LoaderImage} loop={true} /> : children}
      </div>
      <div className='Footer-container'>
        <Footer />
      </div>
      <SelfManagedSnackbar />
    </div>
  );
}

export default Layout;
import React from 'react';
import "./productcart.scss";
import { useSelector } from 'react-redux'
import { calculatePercentageDifference } from "../../../utlis/index"
import CustomButton from "../../Button/index";
import { useNavigate } from 'react-router-dom';
import default_img from "../../../assets/image/Default1.jpg"
// import Loader from "../../../assets/loader.json"
// import Lottie from "lottie-react";

// Define a type for the content items
interface ContentItem {
    images: any;
    name: string;
    category: string;
    max_price: string;
    base_price: string;
    image_gallery: any;
    _id: string;
}

interface AppProps {
    data: ContentItem[];
    loader?: boolean; // Use the defined type for data
}

const ProductCart: React.FC<AppProps> = ({ data, loader }) => {
    const homePageData = useSelector((state: any) => state.homePage.data)
    const navigate = useNavigate()
    return (
        <div className="ProductCart">
            {data.map((content: any, index: any) => (
                <div className="ProductCart-container" key={index}>
                    <img src={(content?.images ? content?.images[0]?.banner : content?.image_gallery[0]?.banner) || default_img} alt={content.name} />
                    <div className='ProductCart-text'>
                        <h1 className='text-primary_text'>{content.name}</h1>
                        <h3 className='text-secondary_text'>{content.category}</h3>
                        <div>
                            <h2 className='text-primary_text'>{homePageData[0]?.currency} {content.base_price}</h2>
                            {content.max_price && <h2 className='line-through text-secondary_text'>{homePageData[0]?.currency} {content.max_price}</h2>}
                        </div>

                    </div>
                    {content.max_price && (
                        <div className="ProductCart-Discount">
                            -{calculatePercentageDifference(Number(content.base_price), Number(content.max_price)).toFixed(0)}%
                        </div>
                    )}
                    <div className="ProductCart-overlay">
                        <CustomButton lable={"View Product"} type="outlined" clickFunction={() => navigate(`/order_details/${content?._id}`)} />
                        {/* <div className='flex p-[10px] justify-center gap-[20px] text-white'>
                            <h3>Share</h3>
                            <h3>Compare</h3>
                            <h3>Likes</h3>
                        </div> */}
                    </div>
                </div>
            ))}
            {/* {loader && <Lottie className="h-[200px] w-full" animationData={Loader} loop={true} />} */}
        </div>
    );
}

export default ProductCart;

import * as React from 'react';
import { useState, useEffect } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import './checbox_with_search.scss'




export default function CustomeSearchCheckbox({ categories, productList, category_id }: any) {
  const [searchTerm, setSearchTerm] = useState("");
  const [showAll, setShowAll] = useState(false);
  const [checkedIds, setCheckedIds] = useState<string[]>([]);

  // Filter the categories based on the search term
  const filteredCategories = categories?.value.filter((item: any) =>
    item.value.toLowerCase().includes(searchTerm.toLowerCase())
  );




  // Determine how many items to show based on `showAll` state
  const itemsToShow = showAll ? filteredCategories : filteredCategories.slice(0, 10);

  const handleCheckboxChange = (key: string, id: string) => {
    // Update the state
    const updatedCheckedIds = checkedIds.includes(id)
      ? checkedIds.filter((checkedId) => checkedId !== id) // Remove if already checked
      : [...checkedIds, id]; // Add if not already checked

    setCheckedIds(updatedCheckedIds); // Update the state

    // Get the current URL
    const currentUrl = new URL(window.location.href);

    // Extract the current path
    let currentPath = currentUrl.pathname;

    // Remove any existing "category=" segment
    currentPath = currentPath.replace(new RegExp(`%20${key}=[^/]+`), "");

    // Add the updated "category=" segment if there are selected IDs
    if (updatedCheckedIds.length > 0) {
      const categoryPath = `%20${key}=${updatedCheckedIds.join("%25")}`; // URL-encoded '%' is '%25'
      currentPath += categoryPath;
    }

    // Update the browser URL without reloading the page
    window.history.replaceState(null, "", currentPath);
  };




  useEffect(() => {
    // Parse the current URL
    const currentUrl = new URL(window.location.href);

    // Extract the path after `/products/`
    const pathAfterProducts = currentUrl.pathname.split("/products/")[1] || "";

    // Split by `%20category=` to isolate the category part
    const categoryPart = pathAfterProducts.split("%20category=")[1];

    if (categoryPart) {
      // Decode the entire category part
      const decodedCategoryPart = decodeURIComponent(categoryPart);

      // Split by ',' or '%' to handle both separators
      const subcategoryIds = decodedCategoryPart.split(/[,|%]/).map((id) => id.trim());

      // Update the state with the extracted IDs as an array
      setCheckedIds(subcategoryIds.filter((id) => id)); // Filter out any empty strings
    }
  }, []);




  useEffect(() => {
    productList({ data: checkedIds, key: categories?.key, category_id: category_id });
  }, [categories?.key, category_id, checkedIds])


  // console.log(itemsToShow, categories)

  return (
    <FormControl fullWidth className='filter-div'>
      <FormLabel className='label'>{categories?.title}</FormLabel>
      <TextField
        id="outlined-basic"
        variant="outlined"
        placeholder={`Search for the ${categories?.title}`}
        size="small"
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <FormGroup>
        {itemsToShow.map((item: any, index: any) => (
          <FormControlLabel
            key={index?._id}
            control={<Checkbox
              className="checkbox-button peer"
              checked={checkedIds.includes(item._id)}
              onChange={() => handleCheckboxChange(categories?.key, item._id)}
            />}
            label={item?.value}
          />
        ))}
      </FormGroup>
      {filteredCategories.length > 10 && (
        <Button className='button' onClick={() => setShowAll(!showAll)}>
          {showAll ? "- Show Less" : `+ Show More (${filteredCategories?.length - 10} more)`}
        </Button>
      )}
    </FormControl>
  );
}

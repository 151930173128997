import {
    Table,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    Paper,
    Box,
    FormControl,
    Select,
    MenuItem,
    Divider,
} from "@mui/material";
import defaultImages from "../../assets/image/Default1.jpg"
import CustomButton from "./../Button";
import "./table.scss";
import { removeOrderDetails, useAppDispatch, fetchOrderDetails, updateOrderDetails } from "../../pages/Cart/controller";
// import Lottie from "lottie-react";
// import emptyCartAnimation from "../../assets/empty_cart.json";

const TableComponent = ({ headers, data }: any) => {

    const dispatch = useAppDispatch();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const handleRemove = async (ID: any) => {

        try {
            await dispatch(removeOrderDetails(ID))
        } catch (errors) {
            console.log(errors)
        }
    }




    const renderRow = (item: any) => {

        const editOrderDetails = (item: any, form_name?: string, form_id?: string, user_selection?: string,) => {
            // console.log(item, form_name, form_id, user_selection)
            if (form_name) {
                const obj = {
                    product_id: item.product_id,
                    order_id: item.order_id,
                    [form_name]: item[form_name].map((item: any) => (
                        item._id === form_id ? { _id: item?._id, user_selection: {_id: user_selection} } : { _id: item?._id, user_selection: item?.user_selection }
                    ))
                }
                dispatch(updateOrderDetails(obj))
            }
        }
        return (
            <TableRow key={item._id} hover >
                <TableCell>

                    <div className="Table flex sm:flex-row flex-col sm:items-start items-start gap-[20px] w-full mb-4">
                        <div className="flex sm:w-[20%] w-full">
                            <img
                                src={item?.images[0]?.thumbnail || defaultImages}
                                alt={item?.images[0]?.thumbnail}
                                className="sm:w-40 w-full sm:h-40 h-80 sm:mr-4 mr-0 rounded-[10px] object-cover"
                            />
                        </div>
                        <div className="flex sm:flex-row flex-col sm:gap-[20px] gap-[20px] items-start justify-between sm:w-[80%] w-full h-full">
                            <div className="flex flex-col justify-start items-start gap-[10px] w-full h-full">
                                <div className="sm:flex-row flex-col w-full">
                                    <h2 className='text-primary_text'> {item.product_name}</h2>
                                    <h3 className='text-secondary_text'> {"Bedroom"} </h3>
                                </div>
                                <div className="flex gap-[10px] sm:flex-row flex-col w-full">
                                    {Array.isArray(item?.checkout_forms) && item?.checkout_forms.map((form: any, index: any) => {
                                        const selectedMultipleSelect = form?.size_form_type === "Multi Select" ? form?.user_selection.map((value: any) => value?._id) : []
                                        console.log("selectedMultipleSelect", selectedMultipleSelect)
                                        return (
                                            <div key={index}>
                                                {form?.size_form_type === "Multi Select" ?
                                                    (<Box sx={{ minWidth: 120, width: '100%', }}>
                                                        {/* <p>{form?.size_field_name}</p> */}
                                                        <FormControl fullWidth size="small" className="custom-select">
                                                            {/* <InputLabel id="demo-multiple-name-label">{form?.size_field_name}</InputLabel> */}
                                                            <Select
                                                                labelId="demo-multiple-name-label"
                                                                id="demo-multiple-name"
                                                                multiple
                                                                className='h-[30px]'
                                                                value={selectedMultipleSelect}
                                                                onChange={(e) => editOrderDetails(item, "checkout_forms", form?._id, e.target.value)}
                                                                // label={form?.size_field_name}
                                                                MenuProps={MenuProps}
                                                            >
                                                                {form?.size_options.map((options: any, index: any) => (
                                                                    <MenuItem
                                                                        key={index}
                                                                        value={options?._id}
                                                                    >
                                                                        {options?.detail}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl> </Box>) : (<Box display="flex" flexWrap="wrap" gap={1} className="h-[30px]">
                                                            <Box sx={{ minWidth: 120, width: '100%', }}>
                                                                {/* <p>{form?.size_field_name}</p> */}
                                                                <FormControl fullWidth size="small" className="custom-select">
                                                                    {/* <InputLabel id="demo-simple-select-label">{form?.size_field_name}</InputLabel> */}
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={form?.user_selection?._id}
                                                                        className='h-[30px]'
                                                                        // label={form?.size_field_name}
                                                                        onChange={(e) => editOrderDetails(item, "checkout_forms", form?._id, e.target.value)}
                                                                    >
                                                                        {form?.size_options?.map((item: any) => (
                                                                            <MenuItem value={item._id}>{item.detail}</MenuItem>
                                                                        ))}

                                                                    </Select>
                                                                </FormControl>
                                                            </Box >
                                                        </Box>)}
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="flex gap-[10px] sm:flex-row flex-col w-full">
                                    {Array.isArray(item?.public_sizes) && item?.public_sizes.map((form: any, index: any) => {
                                        const selectedMultipleSelect = form?.size_form_type === "Multi Select" ? form?.user_selection?.map((value: any) => value?._id) : []
                                        console.log("selectedMultipleSelect", selectedMultipleSelect)
                                        return (
                                            <div key={index}>
                                                {form?.size_form_type === "Multi Select" ?
                                                    (<Box sx={{ minWidth: 120, width: '100%', }}>
                                                        <FormControl fullWidth size="small" className="custom-select">
                                                            {/* <InputLabel id="demo-multiple-name-label">{form?.size_field_name}</InputLabel> */}
                                                            <Select
                                                                labelId="demo-multiple-name-label"
                                                                id="demo-multiple-name"
                                                                multiple
                                                                className='h-[30px]'
                                                                value={selectedMultipleSelect}
                                                                onChange={(e) => editOrderDetails(item, "public_sizes", form?._id, e.target.value)}
                                                                // label={form?.size_field_name}
                                                                MenuProps={MenuProps}
                                                            >
                                                                {form?.size_options.map((options: any, index: any) => (
                                                                    <MenuItem
                                                                        key={index}
                                                                        value={options?._id}
                                                                    // style={getStyles(value?._id, selectedMultipleSelect, theme)}
                                                                    >
                                                                        {options?.detail}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl> </Box>)
                                                    :
                                                    (<Box display="flex" flexWrap="wrap" gap={1} className="h-[30px]">
                                                        <Box sx={{ minWidth: 120, width: '100%', }}>
                                                            <FormControl fullWidth size="small" className="custom-select">
                                                                {/* <InputLabel id="demo-simple-select-label">{form?.size_field_name}</InputLabel> */}
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    value={form?.user_selection?._id}
                                                                    className='h-[30px]'
                                                                    // label={form?.size_field_name}
                                                                    onChange={(e) => editOrderDetails(item, "public_sizes", form?._id, e.target.value)}
                                                                >
                                                                    {form?.size_options?.map((item: any) => (
                                                                        <MenuItem value={item._id} className="flex items-center justify-center">
                                                                            {form?.size_form_type === "Color Select" ?
                                                                                <div className="rounded-[50%] h-[15px] w-[15px]" style={{ backgroundColor: item?.detail }}></div>
                                                                                :
                                                                                item.detail}</MenuItem>
                                                                    ))}

                                                                </Select>
                                                            </FormControl>
                                                        </Box >
                                                    </Box>)
                                                }
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="sm:w-[40%] w-full">
                                <div className='flex flex-row  justify-start gap-[20px] sm:items-center items-start w-full'>
                                    <span className="text-primary_text font-[600] text-[15px]">{item?.currency}{item?.sub_total.toFixed(2)}</span>
                                    <span className="text-primary_text font-[600] line-through text-[14px] price-details">{item.max_price}</span>
                                    {/* <h2 className='text-primary_text line-through price-details'>₹ 999</h2> */}
                                </div>
                                <span className='text-primary_text font-medium price-details'>MRP incl. of all taxes</span>
                                <br />
                                <span className='text-primary_text font-medium price-details price_off'>{(Number(item.max_price - item.base_price))} OFF</span>
                            </div>
                            {/* <h2 className='text-primary_text'>{item?.currency}{item.sub_total.toFixed(2)}</h2> */}
                        </div>
                    </div>
                    <Divider />
                    <div className="flex justify-end gap-[20px] w-full mt-4">
                        <CustomButton lable={"Remove"} clickFunction={() => handleRemove(item._id)} type="outlined" />
                        <CustomButton lable={"Add to Wishlist"} type="contained" />
                    </div>


                </TableCell>
            </TableRow >
        )
    };

    return (
        <TableContainer component={Paper} className="bg-gray-800">
            <Table>
                <TableBody>
                    {(Array.isArray(data) && data.length > 0) && data?.map((item: any) => renderRow(item))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TableComponent;



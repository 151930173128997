// import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import banner from "../../Assets/image/banner.png"

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import CustomButton from "../Button";

import './swiper.scss';

// import required modules
import { EffectFade, Navigation, Pagination } from 'swiper/modules';

export default function SwiperComponent({ Content }: any) {

  return (
    <div className='Swiper_component'>
      <Swiper
        spaceBetween={30}
        effect={'fade'}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        modules={[EffectFade, Navigation, Pagination]}
        className="mySwiper"
      >
        {Content?.images.map((image: any, index: any) => {
          return <SwiperSlide key={index}>
            <img src={image?.banner} alt=""/>
          </SwiperSlide>
        })}

      </Swiper>
      {Content?.title && Content?.tagline && Content?.description && <div className="SwiperContent">
        <h5 className='SwiperContent_title'>
          {Content?.title}
        </h5>
        <h1 className='SwiperContent_tagline'>
          {Content?.tagline}
        </h1>
        <p className='SwiperContent_description'>
          {Content?.description}
        </p>
        <CustomButton lable={"Explore more"} type="contained" />
      </div>}
    </div>
  );
}

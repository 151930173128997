import Layout from "../../component/Layout/index"
import Description from "../../component/Text/Description";
import Title from "../../component/Text/Title";
import "./category.scss"
import ProductCart from "../../component/Cart/ProductCart";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from 'react-redux'
import RadioButtonsGroup from "../../component/Groupradio";
import CustomeSearchCheckbox from "../../component/Checkbox_with_search";
import SizeSelector from "../../component/Select_box_with_search";
import CustomeSelect from "../../component/Select_Input";
import { useLocation } from 'react-router-dom';
import { getRequest } from "../../services";
import { AddProductsCategories, fetchProductsCategories } from "./controller";
import { useAppDispatch } from "./controller";
import { Filter, ArrowUpWideNarrow } from 'lucide-react';


interface BottomSheetProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

const BottomSheet = ({ isOpen, onClose, children }: BottomSheetProps) => {
    return (
        <>
            {/* Overlay */}
            {isOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
                    onClick={onClose}
                />
            )}

            {/* Bottom Sheet */}
            <div style={{ height: "calc(100% - 20px)" }} className={`
          fixed bottom-0 left-0 right-0
          transform transition-transform duration-300 ease-in-out
          bg-white rounded-t-2xl shadow-lg
          h-full w-full
          
          ${isOpen ? 'translate-y-0' : 'translate-y-full'}
        `  }>
                {/* Handle bar for visual indication */}
                <div className="flex justify-center pt-4 pb-2">
                    <div className="w-12 h-1.5 bg-gray-300 rounded-full" />
                </div>

                {/* Content */}
                <div className="px-4 pb-4 h-full w-full">
                    {children}
                </div>
            </div>
        </>
    );
};
function CategoryPage() {
    const location = useLocation();
    const path = location.pathname;
    // Extract the ID after `/products/` and before `%20`
    const category_id = path.split("/products/")[1]?.split("%20")[0]
    const dispatch = useAppDispatch();
    const homePageData = useSelector((state: any) => state.homePage.data)
    const productList = useSelector((state: any) => state.categoryPage.data)
    const [categoryDetails, setCategoryDetails] = useState<any>(null);
    const [filter, setFilter] = useState<any>([])
    const [store_id, setStore_id] = useState<any>(null);
    const [filterationObj, setFilterationobj] = useState<any>({})
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const [openSort, setOpenSort] = useState<boolean>(false);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(2)
    const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
    const [selectedValues, setSelectedValues] = useState<{ [key: string]: any }>({});
    const [selectedSort, setSelectedSort] = useState<string | null>(null);


    useEffect(() => {
        const categories = homePageData[0]?.categories;
        if (category_id && categories) {
            const Category = categories.filter((item: any) => (item._id === category_id))
            setCategoryDetails(Category[0])
            getFilterData(category_id)
        }
    }, [category_id, homePageData]);

    const defaultProductList = async ({ data, key, category_id }: { data: any, key?: any, category_id: string }) => {

        setFilterationobj((prevState: any) => {
            console.log(data, "data")
            const newValue = data.length === 0
                ? (category_id != null ? [category_id] : [])
                : category_id != null
                    ? [...data] // Add category_id if valid
                    : data[0]; // Keep data unchanged if category_id is invalid


            const newEntry = { key, value: newValue };
            const oldFilter = prevState.filter || [];

            // Replace if key exists, otherwise add new entry
            const updatedFilter = [
                ...oldFilter.filter((item: any) => item.key !== key), // Keep all except matching key
                newEntry, // Add or replace
            ];
            return {
                ...prevState,
                filter: updatedFilter, // Updated filter array
                page: 1,              // Reset pagination
                rpp: 10,              // Results per page
                store_id              // Ensure store_id is always present
            };
        });

        // Ensure the state update is reflected before dispatching
        await new Promise((resolve) => setTimeout(resolve, 0));

        // Dispatch the fetch action with the updated filteration object
        // dispatch(fetchProductsCategories(filterationObj));
    };



    useEffect(() => {
        if(filterationObj?.store_id) {
        dispatch(fetchProductsCategories(filterationObj));
        }
    }, [dispatch, filterationObj])


    const getFilterData = async (product_id: any) => {
        try {
            const url = `store-category?$filter=_id%20eq%20${product_id}`
            const filterData = await getRequest(url)
            setFilter(filterData?.data?.data[0]?.filters)
            setStore_id(filterData?.data?.data[0]?.store_id)

        } catch (error) {
            // console.log(error)
        }
    }

    const sortingOptions = [
        { value: "a-to-z", label: "A to Z" },
        { value: "price-high-to-low", label: "Price-High to Low" },
        { value: "price-low-to-high", label: "Price-Low to High" },
        { value: "newest", label: "Newest" },
        { value: "popularity", label: "Popularity" }
    ];


    const handleFilterClick = (title: string) => {
        setSelectedFilter(title === selectedFilter ? null : title);
    };

    const handleCheckboxChange = (key: string, value: string) => {
        setSelectedValues((prev) => ({
            ...prev,
            [key]: prev[key]?.includes(value)
                ? prev[key].filter((item: string) => item !== value)
                : [...(prev[key] || []), value],
        }));

        const newData = {
            key: key, value: selectedValues[key]?.includes(value) ? selectedValues[key]?.filter((item: string) => item !== value) : [...(selectedValues[key] || []), value]
        }
        if (!newData?.value?.includes(category_id)) {
            newData?.value.push(category_id)
        }
        // Replace existing key or add new one
        const newFiltervalue = [
            ...filterationObj.filter.filter((item: any) => item.key !== newData.key),
            newData,
        ];
        setFilterationobj({ ...filterationObj, filter: newFiltervalue });
    };
    const handleRadioChange = (key: string, value: string) => {
        // console.log(key, value)
        setSelectedValues({ ...selectedValues, [key]: value });
        const newData = {
            key: key, value: value
        }
        // Replace existing key or add new one
        const newFiltervalue = [
            ...filterationObj.filter.filter((item: any) => item.key !== newData.key),
            newData,
        ];
        setFilterationobj({ ...filterationObj, filter: newFiltervalue });
    };

    const handleScroll = useCallback(() => {

        if (filterationObj?.page) {
            setLoading(true)
            const obj = {
                filter: filterationObj?.filter,
                page: page,
                rpp: filterationObj?.rpp,
                store_id: filterationObj?.store_id,
            }
            console.log(obj, filterationObj?.page)
            dispatch(AddProductsCategories(obj));
            setPage(page + 1)
            setLoading(false)
        }
    }, [dispatch, filterationObj, page]);

    return (
        <Layout handleScroll={handleScroll}>
            <div className="Category-Page">
                <img src={homePageData[0]?.store_design?.carosel?.images[0]?.banner} alt="category-banner" className="Category-banner" />
                <div className="Category-Page-Content">
                    <div className="Category-section">

                        <div className="Category-section-with-sort">
                            <div className="Category-Name-and-filter">
                                <div className="Category-section-information">
                                    <Title>Home / {categoryDetails?.category_name}</Title>
                                    <Description> DC Comics Official Merchandise - 236 items</Description>
                                </div>
                                <div className="Categroy-Filter" >
                                    <CustomeSelect label={false} Content={sortingOptions} />
                                </div></div>

                        </div>

                        <div className="Category-flex-component">
                            <div className="Category-section-filter">
                                {filter.length > 0 && filter?.map((item: any) => {

                                    if (item?.type === "Multi Select") {
                                        return <CustomeSearchCheckbox categories={item} productList={defaultProductList} category_id={categoryDetails?._id} />;
                                    }
                                    if (item?.type === "Single Select") {
                                        return <RadioButtonsGroup content={item} productList={defaultProductList} />;
                                    }
                                    if (item?.type === "Chip Select") {
                                        return <SizeSelector categories={item} />;
                                    }
                                    return null; // In case none of the conditions match
                                })}
                            </div>



                            <div className="Category-section-information">
                                <div className="Category-section-content">
                                    <ProductCart data={productList ?? []} loader={loading} />
                                </div>
                            </div>

                        </div>




                    </div>
                </div>
            </div>
            <div className="Category-section-filter-sm">
                <div className="flex justify-center items-center w-[50%]" onClick={() => setOpenFilter(!openFilter)}>
                    <Filter className="w-5 h-5" />
                    <span className="text-primary_text font-medium text-[12px]">Filter</span>
                </div>
                <div className="flex justify-center items-center w-[50%]" onClick={() => setOpenSort(!openSort)}>
                    <ArrowUpWideNarrow className="w-5 h-5" />
                    <span className="text-primary_text font-medium text-[12px]">Sort</span>
                </div>
            </div>
            <BottomSheet isOpen={openFilter} onClose={() => setOpenFilter(!openFilter)}>
                <div className="space-y-4 h-full w-full" >
                    <button
                        onClick={() => setOpenFilter(!openFilter)}
                        className="w-full px-4 py-2 bg-gray-200 rounded-lg"
                    >
                        Close
                    </button>
                    <div className="flex h-full w-full">
                        {/* Left Section - Filter Titles */}
                        <ul className="w-1/3 border-r">
                            {filter.map((filter: any) => (
                                <li
                                    key={filter.title}
                                    className={`p-2 cursor-pointer ${selectedFilter === filter.title ? "bg-gray-300" : "bg-white"}`}
                                    onClick={() => handleFilterClick(filter.title)}
                                >
                                    {filter.title}
                                </li>
                            ))}
                        </ul>

                        {/* Right Section - Filter Values */}
                        <div className="w-2/3 px-4">
                            {selectedFilter &&
                                filter
                                    .filter((filter: any) => filter.title === selectedFilter)
                                    .map((item: any) => {
                                        // console.log(item)
                                        return (
                                            <div key={item?.key}>
                                                {item.value.map((val: any) => (
                                                    <label key={val?._id} className="flex flex-row-reverse justify-between items-center border-b border-gray-200 py-2">
                                                        <input
                                                            type={item?.type === "Multi Select" ? "checkbox" : "radio"}
                                                            name={item?.key}
                                                            value={val?._id}
                                                            checked={selectedValues[item?.key]?.includes(val?._id) || selectedValues[item.key] === val?._id}
                                                            onChange={() =>
                                                                item.type === "Multi Select"
                                                                    ? handleCheckboxChange(item.key, val?._id)
                                                                    : handleRadioChange(item.key, val?._id)
                                                            }
                                                            className="mr-2"
                                                        />
                                                        {val?.value}
                                                    </label>
                                                ))}
                                            </div>
                                        )
                                    })}
                        </div>
                    </div>


                </div>
            </BottomSheet>
            <BottomSheet isOpen={openSort} onClose={() => setOpenSort(!openSort)}>
                <div className="space-y-4 h-full w-full" >
                    <button
                        onClick={() => setOpenSort(!openSort)}
                        className="w-full px-4 py-2 bg-gray-200 rounded-lg"
                    >
                        Close
                    </button>
                    <div className="flex flex-col h-full w-full">
                        {/* Full Section - Filter Values */}
                        <div className="w-full px-4">
                            {sortingOptions.map((item: any) => {
                                return (
                                    <div key={item?.value}>
                                        <label className="flex flex-row-reverse justify-between items-center border-b border-gray-200 py-2">
                                            <input
                                                type={"radio"}
                                                name={item?.value}
                                                value={item?.value}
                                                checked={selectedSort === item?.value}
                                                onChange={() =>
                                                    setSelectedSort(item.value)
                                                }
                                                className="mr-2"
                                            />
                                            {item?.label}
                                        </label>
                                    </div>
                                )
                            })}
                        </div>
                    </div>


                </div>
            </BottomSheet>
        </Layout>
    );
}

export default CategoryPage;

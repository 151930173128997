import { useEffect, useState } from 'react';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import "./product_image.scss"



export default function ProductImages({ ImageList }: any) {

    const [selectedImage, setSelectedImage] = useState<any>("");
    useEffect(() => {
        if (Array.isArray(ImageList) && ImageList.length > 0) {
            setSelectedImage(ImageList[0]?.banner)
        } else {
            setSelectedImage("")
        }

    }, [ImageList])



    return (
        <div className='w-full flex sm:flex-row flex-col-reverse items-start justify-between sm:p-[40px] p-[10px] sm:gap-[0px] gap-[20px]'>
            <div className='sm:w-[12%] w-[100%] flex sm:flex-col flex-row flex-wrap items-stretch justify-between sm:gap-[30px] gap-[10px]'>
                {ImageList?.map((img: any, index: any) => (
                    <img
                        key={index}
                        src={img?.banner}
                        alt={`Thumbnail ${index}`}
                        onClick={() => setSelectedImage(img?.banner)}
                        className='sm:w-full w-[20%] h-[60px] cursor-pointer object-cover sm:mr-[10px] mr-[0px] rounded-[10px]'

                    />
                ))}
            </div>
            <div className='Zoom_image sm:w-[78%] w-full'>
                {/* <ReactImageMagnify {...{
                    smallImage: {
                        alt: 'Wristwatch by Ted Baker London',
                        isFluidWidth: true,
                        src: selectedImage
                    },
                    largeImage: {
                        src: selectedImage,
                        width: 1200,
                        height: 1800
                    }
                }} /> */}
                <InnerImageZoom src={selectedImage} zoomSrc={selectedImage} zoomType="hover" className='image' />
                {/* <Zoom>
                    <img
                        src={selectedImage}
                        alt="Selected"
                        className='w-full sm:max-h-[700px] max-h-[350px] object-cover rounded-[10px]'
                    />
                </Zoom> */}
            </div>

        </div>
    );
}
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch } from '../../state_store/index' // Importing AppDispatch type for Thunks
import { useDispatch } from 'react-redux';


interface LoaderState {
    data: any
}

const initialState: LoaderState = {
    data: {
        open: false,
    }
}

const LoaderSlice = createSlice({
    name: 'Loader',
    initialState,
    reducers: {
        setLoader(state, action: PayloadAction<any>) {
            state.data = action.payload  // Update state with action payload
        }
    }
})

// Export actions
export const { setLoader } = LoaderSlice.actions
// Export reducer
export default LoaderSlice.reducer

export const useAppDispatch: () => AppDispatch = useDispatch;
import React from 'react';
import "./footer.scss";
import { useSelector } from 'react-redux'

// Define a type for the content items


const Footer = () => {
    const homePageData = useSelector((state: any) => state.homePage.data)
    return (
        <div className='Footer-Component'>
            <div className="Footer-Component-Content">
                <div className='Footer-Section Address'>
                    <h1 className='text-primary_text'>{homePageData[0]?.store_name}</h1>
                    <h2 className='text-secondary_text'>{homePageData[0]?.full_address}</h2>
                </div>
                <div className='Footer-Section Links'>
                    <h1 className='text-secondary_text'>Links</h1>
                    <h2 className='text-primary_text'>Home</h2>
                    <h2 className='text-primary_text'>Shop</h2>
                    <h2 className='text-primary_text'>About</h2>
                    <h2 className='text-primary_text'>Contact</h2>
                </div>
                <div className='Footer-Section Help'>
                    <h1 className='text-secondary_text'>Help</h1>
                    <h2 className='text-primary_text'>Payment Option</h2>
                    <h2 className='text-primary_text'>Return</h2>
                    <h2 className='text-primary_text'>Privacy Policy</h2>

                </div>
                <div className='Footer-Section Newsletter'>
                    <h1 className='text-secondary_text'>New Letter</h1>
                    <h2 className='text-primary_text'>Subscribe</h2>
                </div>

               
            </div>
            <h5 className='pt-[40px]'>2025 {homePageData[0]?.store_name} All rights reverved</h5>
        </div>

    );
}

export default Footer;
